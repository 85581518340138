body {
  margin: 0;
  height: 100vh;
}

#paper-props::-webkit-scrollbar {
  width: 14px;
}
#paper-props {
  overflow: hidden;
}
#paper-props:hover {
  overflow-y: overlay;
}
#paper-props::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
  color: #e5e6e7;
}
#paper-props::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
#paper-props::-webkit-scrollbar-corner {
  background-color: transparent;
}

#paper-props {
  transition: color 0.3s;
}

#dropdown-paper {
  transition: color 0.3s;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
  max-height: 350px;
  width: 160px;
}

#menu-paper {
  transition: color 0.3s;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.07);
  max-height: 350px;
  width: 160px;
  overflow: hidden;
}

#menu-paper ::-webkit-scrollbar {
  width: 14px;
}
#menu-paper ::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 10px;
  color: #e5e6e7;
}
#menu-paper::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
#menu-paper::-webkit-scrollbar-corner {
  background-color: transparent;
}
